/* ==================== */
/* 書類一覧画面 */
/* ==================== */
import React, { useEffect, useMemo, useState, useContext } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import SortingButton from '../../components/SortingButton/SortingButton';
import { RESPONSE } from '../../const/Enum';
import useWindowSize from '../../hook/useWindowSize';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './PaperList.css';
import PDFThumbnail from '../../components/PDFThumbnail/PDFThumbnail';
import PaperViewerModal from '../../components/PaperViewerModal/PaperViewerModal';

const PaperList = () => {
  const [itemList, setItemList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'paperId', direction: true });
  const [totalCount, setTotalCount] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const size = useWindowSize();
  const sortOptions = [
    { key: 'paperId', value: { key: 'paperId', direction: true }, label: '標準' },
    { key: 'date_asc', value: { key: 'date', direction: false }, label: '日付（昇順）' },
    { key: 'date_desc', value: { key: 'date', direction: true }, label: '日付（降順）' }
  ];
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedPaper, setSelectedPaper] = useState(null);
  const [isInit, setIsInit] = useState(true);
  
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      // 初期処理右画面を非表示
      changeScreenMode(MODE.NONE);
      
      const params = {
        page: 1,
        isDesc: sortConfig.direction,
        orderKey: sortConfig.key,
        name: searchTerm,
      };
    
      const res = await requestApiLoad('/paperList/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }

      // paperPathをS3のURLに変換
      const selectedItemWithS3Urls = res.selectedItem ? {
        ...res.selectedItem,
        paperPath: `${process.env.REACT_APP_S3_URL}/${res.selectedItem.paperPath}`
      } : {};
      // paperPathをS3のURLに変換
      const paperListWithS3Urls = res.paperList.map(paper => ({
        ...paper,
        paperPath: `${process.env.REACT_APP_S3_URL}/${paper.paperPath}`
      }));

      setSelectedItem((res.paperList && res.paperList.length > 0) ? selectedItemWithS3Urls : {});
      setItemList((res.paperList && res.paperList.length > 0) ? paperListWithS3Urls : []);
      setCurrentPage(1);
      setLastPage(res.lastPage);
      setTotalCount(res.dataCount);

      // 初期表示時のみ詳細画面を表示
      if (size.width > 1200) {
        if (isInit) {
          handleDetail(res.paperList[0]);
          setIsInit(false);
        }
      }
    }

    fetchData();
  }, [sortConfig.direction, sortConfig.key, searchTerm]);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    DETAIL: 'DETAIL',
  }

  const changeScreenMode = (mode) => {
    switch (mode) {   
      case MODE.DETAIL:
        setShowDetail(true);
        break;
   
      case MODE.NONE:
      default:
        setShowDetail(false);
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);

    const params = {
      page: 1,
      isDesc: sortConfig.direction,
      orderKey: sortConfig.key,
      name: searchTerm,
    };
    
    const res = await requestApiLoad('/paperList/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    // paperPathをS3のURLに変換
    const selectedItemWithS3Urls = res.selectedItem ? {
      ...res.selectedItem,
      paperPath: `${process.env.REACT_APP_S3_URL}/${res.selectedItem.paperPath}`
    } : {};
    // paperPathをS3のURLに変換
    const paperListWithS3Urls = res.paperList.map(paper => ({
      ...paper,
      paperPath: `${process.env.REACT_APP_S3_URL}/${paper.paperPath}`
    }));
    
    setSelectedItem(selectedItemWithS3Urls);
    setItemList(paperListWithS3Urls);
    setLastPage(res.lastPage);
    setTotalCount(res.dataCount);
  }

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'paperId', direction: true } : JSON.parse(event);
    setSortConfig(newSortConfig);
  };

  const sortedItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onClear = () => {
    setSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 詳細
  // ------------------------------------------------------------------------------------
  const handleDetail = async (item) => {
    if (!item) { return; }
    setSelectedItem(item);

    // message/get/{messageId}のAPIで、右画面の詳細データを取得
    const res = await requestApiLoad(`/message/get/${item.messageId}`, {}, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    res.paperList = res.paperList.map(paper => ({
      ...paper,
      paperPath: `${process.env.REACT_APP_S3_URL}/${paper.paperPath}`
    }));
    
    // 詳細データをセット
    setSelectedItem({ ...res });
    // 詳細画面を表示
    changeScreenMode(MODE.DETAIL);

    if (!res.isRead) {
      // 未読の場合はAPIで既読に変更
      const messageRes = await requestApiLoad(`/message/read/${res.messageId}`, {}, setIsLoading);
      if (messageRes.return === RESPONSE.SUCCESS) {
        
        // 右画面のメッセージに含まれる、左画面のファイルの既読状態を更新
        setItemList(prevList => prevList.map(listItem =>
          listItem.messageId === res.messageId
            ? { ...listItem, isMessageRead: true }
            : listItem
        ));
      }
    }
  };

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------
  const createTable = (itemList) => {
    return (
      <div className="paper-list-contents">
        <div className="paper-list">
          {itemList.length === 0 ? (
            <div className="no-data">データがありません</div>
          ) : (
            itemList.map((item) => (
              <div className="paper-item" key={`${item.messageId}-${item.paperId}`} onClick={() => handleDetail(item)}>
                <div className="paper-info-container">
                  <div className="unread-badge-info">
                    {!item.isMessageRead && <div className="unread-badge"></div>}
                  </div>
                  <div className="paper-info">
                    <span className="paper-date">{item.date}</span>
                    <h3 className="message-title">{item.title}</h3>
                  </div>
                </div>
                <PDFThumbnail pdfUrl={item.paperPath} />
              </div>
            ))
          )}
        </div>
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // 詳細
  // ------------------------------------------------------------------------------------
  const createDetailTable = (detailItem) => {
    return (
      <div className="detail-content">
        <div className="detail-header">
          <div className="detail-meta">
            <span className="detail-date">送信日時：{detailItem.date}</span>
            <span className="detail-sender">送信者：{detailItem.senderName}</span>
          </div>
        </div>
        <div className="detail-body">
          <div className="detail-text">
            {detailItem.contents}
          </div>
          <div className="detail-papers">
            {detailItem.paperList && detailItem.paperList.map((doc, index) => (
              <div key={index}>
                <div className="paper-preview" onClick={() => handlePaperPreview(doc)}>
                <span className="paper-title">{doc.paperTitle}</span>
                  <PDFThumbnail
                    pdfUrl={doc.paperPath}
                    isConfirmed={doc.isConfirmed}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // 書類プレビュー
  // ------------------------------------------------------------------------------------
  const handlePaperPreview = (paper) => {
    setSelectedPaper(paper);
    setIsDocumentModalOpen(true);
  };

  // ------------------------------------------------------------------------------------
  // 書類確認
  // ------------------------------------------------------------------------------------
  const handlePaperConfirm = async (confirmed) => {
    // 確認状態を更新
    const res = await requestApiLoad(`/paper/confirm/${selectedItem.messageId}/${selectedPaper.paperId}`, {}, setIsLoading);
    if (res.return === RESPONSE.SUCCESS) {

      if (confirmed && selectedPaper) {
        setSelectedItem(prevItem => ({
          ...prevItem,
          paperList: prevItem.paperList.map(paper =>
            paper.paperId === selectedPaper.paperId
              ? { ...paper, isConfirmed: true }
              : paper
          )
        }));
      }
    }
  };

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="paper-list">
      <Loading isLoading={isLoading} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">書類一覧</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="タイトルで検索"
                value={searchTerm || ''}
                onChange={(e) => setSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: searchTerm ? '30px' : '10px'
                }}
              />
              {searchTerm && (
                <button
                  onClick={onClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <SortingButton
              sortConfig={sortConfig}
              handleSortChange={handleSortChange}
              sortOptions={sortOptions}
            />
          </div>
          
          <Pagination
            totalPages={lastPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>

        {createTable(sortedItems)}
      </div>
      
      {size.width > 1200 ? (
        <>
          {showDetail && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.title}</h2>
              {createDetailTable(selectedItem)}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showDetail}
            title={selectedItem.title}
            closeModal={() => setShowDetail(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              {createDetailTable(selectedItem)}
            </div>
          </Modal>
        </>
      )}

      <PaperViewerModal
        isOpen={isDocumentModalOpen}
        closeModal={() => setIsDocumentModalOpen(false)}
        title={selectedPaper?.paperTitle}
        pdfUrl={selectedPaper?.paperPath}
        onConfirm={handlePaperConfirm}
      />
    </div>
  )
}

export default PaperList;