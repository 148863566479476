/* ==================== */
/* 共通 ログイン画面 */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { RESPONSE, PLAN_TYPE, USER_TYPE } from '../../const/Enum';
import { ErrorMessage, MaxLength } from '../../const/Constant';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import * as Validation from '../../utils/validation';
import './Login.css';
import { useUser } from '../../context/UserContext';

const Login = () => {
  const navigate = useNavigate();
  const [mail, setMail] = useState('');
  const [password, setPassWord] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const cookieSession = Cookies.get('sessionId') || '';
  const { userData, setUserData } = useUser();

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const sessionLogout = async (session) => {
      const res = await requestApiLoad('/auth/logout', { session }, setIsLoading);
      if (res.return === RESPONSE.PROGRESS) {
        return;

      } else if (res.return === RESPONSE.SUCCESS) {
        Cookies.remove('sessionId');
        navigate('/');
      
      } else {
        alert(ErrorMessage.API_ERROR);
      }
    };

    const fetchData = async () => {
      if (cookieSession === null || cookieSession === '') {
        return;
      }

      const res = await requestApiLoad('/auth/sessionLogin', { session: cookieSession }, setIsLoading);
      if (res.return === RESPONSE.PROGRESS) {
        return;
      }
      if (res.return !== RESPONSE.SUCCESS) {
        sessionLogout(cookieSession);
        return;
      }

      setUserData(res.user);
      
      switch (res.role) {
        case USER_TYPE.OWNER:
          navigate('/customer-master', { state: { role: res.user.role } });
          break;
        
        case USER_TYPE.ADMIN:
          if (Number(res.planType) === PLAN_TYPE.JS) {
            // JobSignプランの場合は「スタッフ設定」に遷移
            navigate('/staff-setting', { state: { role: res.user.role } });
          } else {
            // それ以外の場合は「研修設定」に遷移
            navigate('/job-setting', { state: { role: res.user.role } });
          }
          break;
        
        case USER_TYPE.STAFF:
          if (Number(res.planType) === PLAN_TYPE.JS) {
            // JobSignプランの場合は「書類一覧」に遷移
            navigate('/paper-list', { state: { role: res.user.role } });
          } else {
            // それ以外の場合は「研修一覧」に遷移
            navigate('/job-list', { state: { role: res.user.role } });
          }
          break;
        default:
          navigate('/');
          break;
      }
    };

    fetchData();
  }, [cookieSession, navigate]);

  // ------------------------------------------------------------------------------------
  // ログイン処理
  // ------------------------------------------------------------------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Validation.validateLogin(mail, password).error) {
      alert(Validation.validateLogin(mail, password).message);
      return;
    }
    
    const res = await requestApiLoad('/auth/login',
      { mail: mail, password: password }, setIsLoading);
    if (res.return === RESPONSE.PROGRESS) {
      return;
    }
    if (res.return !== RESPONSE.SUCCESS) {
      alert(ErrorMessage.INVALID_EMAIL_OR_PASSWORD);
      return;
    }

    setUserData(res.user);
    Cookies.set('sessionId', res.session);

    switch (res.user.role) {
      case USER_TYPE.OWNER:
        navigate('/customer-master', { state: { role: res.user.role } });
        break;
      
      case USER_TYPE.ADMIN:
        if (Number(res.planType) === PLAN_TYPE.JS) {
          // JobSignプランの場合は「スタッフ設定」に遷移
          navigate('/staff-setting', { state: { role: res.user.role } });
        } else {
          // それ以外の場合は「研修設定」に遷移
          navigate('/job-setting', { state: { role: res.user.role } });
        }
        break;
      
      case USER_TYPE.STAFF:
        if (Number(res.planType) === PLAN_TYPE.JS) {
          // JobSignプランの場合は「書類一覧」に遷移
          navigate('/paper-list', { state: { role: res.user.role } });
        } else {
          // それ以外の場合は「研修一覧」に遷移
          navigate('/job-list', { state: { role: res.user.role } });
        }
        break;
      
      default:
        navigate('/');
        break;
    }
  }
  
  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="login-container">
      <Loading isLoading={isLoading} />
      <form onSubmit={handleSubmit} className="login-form column-group">
        
        <div className="row-set">
          <div className="input-group">
            <label>メールアドレス</label>
            <input
              type="email"
              value={mail}
              onChange={e => setMail(e.target.value)}
              autoComplete="email"
              maxLength={MaxLength.MAIL_MAX_LENGTH}
            />
          </div>
        </div>
    
        <div className="row-set">
          <div className="input-group">
            <label>パスワード</label>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={e => setPassWord(e.target.value)}
              autoComplete="current-password"
            />
          </div>
        </div>

        <div className="visible-button-container">
          <input
            type="checkbox"
            className="visible-button"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          />
          <label
            htmlFor="visible-button"
            onClick={() => setShowPassword(!showPassword)}>パスワード表示
          </label>
        </div>
        <button type="submit" className="submit-button">
          Login
        </button>
      </form>
    </div>
  )
}

export default Login;
